<template>
  <div class="program-container">
    <div
      ref="formBody"
      class="form-container pt-3"
    >
      <!-- Step 1 -->
      <Card v-if="activeStep === 1">
        <template #content>
          <WizardStepHeader
            :stepNumber="1"
            title="Podcast sign up"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepOneSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="program-form"
              @submit.prevent="handleSubmit($event, onSubmitStepOne)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseDropdown
                    v-model="stepOneForm.hostingPlatform"
                    fieldId="hostingPlatform"
                    fieldName="hostingPlatform"
                    fieldLabel="Hosting Platform"
                    :options="HOSTING_PLATFORM_OPTIONS"
                  />

                  <template v-if="stepOneForm.hostingPlatform === HOSTING_PLATFORM_SPREAKER">
                    <SpreakerForm
                      v-model:rssUrl="stepOneForm.spreakerRssUrl"
                      rssUrlFieldName="spreakerRssUrl"
                      v-model:name="stepOneForm.name"
                      v-model:hostName="stepOneForm.hostName"
                      v-model:hostEmail="stepOneForm.spreakerHostEmail"
                      hostEmailFieldName="spreakerHostEmail"
                      @blurRssUrl="onBlurSpreakerRss"
                    />
                  </template>
                  <template v-else-if="stepOneForm.hostingPlatform === HOSTING_PLATFORM_TRITON">
                    <TritonForm
                      v-model:rssUrl="stepOneForm.tritonRssUrl"
                      rssUrlFieldName="tritonRssUrl"
                      v-model:name="stepOneForm.name"
                      v-model:hostName="stepOneForm.hostName"
                      v-model:hostEmail="stepOneForm.tritonHostEmail"
                      hostEmailFieldName="tritonHostEmail"
                      v-model:tapProgramId="stepOneForm.tapProgramId"
                      @blurRssUrl="onBlurTritonRss"
                    />
                  </template>

                  <Divider />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseInputNumber
                    v-model="stepOneForm.averageMonthlyDownloads"
                    fieldId="averageMonthlyDownloads"
                    fieldName="averageMonthlyDownloads"
                    fieldLabel="Average monthly downloads"
                    placeholder="Type number"
                    helperText="Your minimum average
                      monthly downloads must be at least 2,500."
                  />
                </div>

                <div class="col-12">
                  <BaseTextarea
                    v-model="stepOneForm.showDescription"
                    fieldId="showDescription"
                    fieldName="showDescription"
                    fieldLabel="Show Description"
                    helperText="Add a description of your podcast.
                      This will be used as context in your generated ads."
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 2 -->
      <template v-else-if="activeStep === 2">
        <Card
          class="cursor-pointer"
          @click="activeStep = 1"
        >
          <template #content>
            <div class="flex justify-content-between">
              <div class="flex align-items-center">
                <i
                  class="pi pi-check-circle mr-2 text-green-400"
                  style="font-size: 18px;"
                />
                <h2 class="text-base m-0 inline">Step 1</h2>
              </div>
              <i
                class="pi pi-angle-down"
                style="font-size: 18px;"
              />
            </div>
          </template>
        </Card>
      <Card class="mt-3">
        <template #content>
          <WizardStepHeader
            :stepNumber="2"
            title="Podcast sign up"
          />

          <p>
            For your show, please check all that apply
          </p>

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepTwoSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="program-form"
              @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseFieldContainer>
                    <div class="mb-2 font-bold">
                      Target Gender
                    </div>
                    <GenderInput
                      v-model="stepTwoForm.targetGender"
                    />
                  </BaseFieldContainer>

                  <Divider />
                </div>

                <div class="col-12">
                  <BaseFieldContainer>
                    <div class="mb-2 font-bold">Target Age</div>
                    <TargetAgeInput
                      v-model="stepTwoForm.targetAgeRanges"
                    />
                  </BaseFieldContainer>

                  <Divider />
                </div>

                <div class="col-">
                  <BaseFieldContainer>
                    <div class="mb-2 font-bold">Geographic Targeting</div>
                    <GeographicTargetMessage />
                  </BaseFieldContainer>
                </div>

                <div class="col-12">
                  <CategoryInput
                    fieldLabel="Relevant Categories"
                    fieldName="categories"
                    :categoryOptions="categoryOptions"
                    v-model="stepTwoForm.categories"
                    helperText="Choose all categories that describe the content of your podcasts."
                  />
                </div>

                <div class="col-12">
                  <Divider />

                  <BaseCheckbox
                    v-model="stepTwoForm.containsSensitiveContent"
                    fieldId="containsSensitiveContent"
                    fieldName="containsSensitiveContent"
                    fieldLabel="My show includes explicit content"
                    binary
                    :trueValue="1"
                    :falseValue="0"
                  />

                  <Divider />
                </div>

                <div class="col-12">
                  <CategoryInput
                    fieldLabel="Exclude Advertising Categories"
                    fieldName="categoriesBlacklist"
                    :categoryOptions="sensitiveCategoryOptions"
                    v-model="stepTwoForm.categoriesBlacklist"
                    helperText="These are the advertising categories
                      you are unwilling to work with."
                  />

                  <Divider />
                </div>

                <div class="col-12">
                  <BaseCheckbox
                    v-model="stepTwoForm.approvalBehavior"
                    fieldId="approvalBehavior"
                    fieldName="approvalBehavior"
                    fieldLabel="Automatically approve ads"
                    helperText="<p>
                        This option determines if campaigns that
                        match your podcast should automatically be approved
                        and run without user intervention.
                      </p>
                      <p>
                        If you opt out of this feature,
                        you will be prompted to approve or decline campaigns before the
                        campaign can start.
                      </p>"
                    binary
                    :trueValue="AD_APPROVAL_BEHAVIOR_AUTOMATIC"
                    :falseValue="AD_APPROVAL_BEHAVIOR_MANUAL"
                  />

                  <Divider />
                </div>

                <div class="col-12">
                  <AdPreferencesInput
                    v-model="stepTwoForm.adPreferences"
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>
      </template>

      <ConfirmDialog
        v-model:visible="unsavedChangesDialogIsVisible"
        header="You have unsaved changes"
        @confirm="() => {
          this.formHasChanges = false;
          this.unsavedChangesDialogIsVisible = false;
          this.onCancel();
        }"
        confirmButtonLabel="Leave"
        cancelButtonLabel="Stay"
      >
        <p>
          There are unsaved changes to your podcast.
          Are you sure you want to leave?
        </p>
      </ConfirmDialog>
    </div>

    <div class="form-container flex justify-content-between flex-wrap py-4 row-gap-1">
      <Button
        text
        plain
        :label="cancelButtonLabel"
        :disabled="isSubmitting"
        @click="onCancel"
      />
      <div class="flex">
        <Button
          v-if="activeStep !== 1"
          text
          plain
          label="Previous"
          :disabled="isSubmitting"
          @click="onClickPrevious"
        />
        <Button
          class="ml-2"
          :label="nextButtonText"
          type="submit"
          form="program-form"
          :loading="isSubmitting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import {
  array,
  object,
  string,
  number,
} from 'yup';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import {
  AGE_GROUP_OPTIONS,
  AD_APPROVAL_BEHAVIOR_MANUAL,
  AD_APPROVAL_BEHAVIOR_AUTOMATIC,
  GENDER_ALL,
  HOSTING_PLATFORM_SPREAKER,
  HOSTING_PLATFORM_OPTIONS,
  HOSTING_PLATFORM_TRITON,
} from '@/constants';
import * as api from '@/api';
import TargetAgeInput from '@/components/targetAgeInput';
import GenderInput from '@/components/genderInput';
import CategoryInput from '@/components/categoryInput';
import { parseMessageFromError } from '@/utils/errors';
import WizardStepHeader from '@/components/wizardStepHeader';
import ConfirmDialog from '@/components/confirmDialog';
import { sanitizeAdPreferences } from '@/components/programFormDialog/utils';
import AdPreferencesInput from '@/components/programFormDialog/components/adPreferencesInput';
import GeographicTargetMessage from '@/components/geographicTargetMessage';
import SpreakerForm from '@/components/programFormDialog/components/spreakerForm';
import TritonForm from '@/components/programFormDialog/components/tritonForm';
import { getSanitizedValue } from '@/utils/helpers';

const generateEmptyStepOneForm = () => ({
  hostingPlatform: HOSTING_PLATFORM_SPREAKER,
  spreakerRssUrl: '',
  tritonRssUrl: '',
  name: '',
  hostName: '',
  spreakerHostEmail: '',
  tritonHostEmail: '',
  averageMonthlyDownloads: null,
  tapProgramId: null,
  showDescription: '',
});
const generateEmptyStepTwoForm = () => ({
  targetGender: GENDER_ALL,
  targetAgeRanges: [...AGE_GROUP_OPTIONS],
  categories: [],
  containsSensitiveContent: 0,
  categoriesBlacklist: [],
  approvalBehavior: AD_APPROVAL_BEHAVIOR_MANUAL,
  adPreferences: sanitizeAdPreferences(),
});

export default {
  components: {
    WizardStepHeader,
    TargetAgeInput,
    GenderInput,
    CategoryInput,
    ConfirmDialog,
    AdPreferencesInput,
    GeographicTargetMessage,
    SpreakerForm,
    TritonForm,
  },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
    sensitiveCategoryOptions: {
      type: Array,
      default: () => ([]),
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
  },
  computed: {
    nextButtonText() {
      switch (this.activeStep) {
        case 1:
          return 'Next';
        case 2:
          return 'Submit';
        default:
          return 'Next';
      }
    },
  },
  watch: {
    activeStep() {
      if (this.$refs.formBody) {
        this.$refs.formBody.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
  },
  data() {
    return {
      // unsaved changes feature
      formHasChanges: false,
      stepOneUnwatch: null,
      stepTwoUnwatch: null,
      unsavedChangesDialogIsVisible: false,

      AD_APPROVAL_BEHAVIOR_MANUAL,
      AD_APPROVAL_BEHAVIOR_AUTOMATIC,
      HOSTING_PLATFORM_OPTIONS,
      HOSTING_PLATFORM_SPREAKER,
      HOSTING_PLATFORM_TRITON,
      activeStep: 1,
      stepOneSchema: object({
        spreakerRssUrl: string().matches(/https:\/\/www\.(?:spreaker|beta-spreaker)\.com\/show\/[0-9]+\/episodes\/feed/, 'RSS must match the spreaker format: https://www.spreaker.com/show/PODCASTID/episodes/feed')
          .when([], {
            is: () => this.stepOneForm.hostingPlatform === HOSTING_PLATFORM_SPREAKER,
            then: (schema) => schema.required('RSS url is required'),
            otherwise: (schema) => schema,
          }),
        tritonRssUrl: string()
          .when([], {
            is: () => this.stepOneForm.hostingPlatform === HOSTING_PLATFORM_TRITON,
            then: (schema) => schema.required('RSS url is required'),
            otherwise: (schema) => schema,
          }),
        tapProgramId: string().when([], {
          is: () => this.stepOneForm.hostingPlatform === HOSTING_PLATFORM_TRITON,
          then: (schema) => schema.required('TAP Station ID is required'),
          otherwise: (schema) => schema,
        }),
        name: string().required('Name is required'),
        hostName: string().required('Host name is required'),
        spreakerHostEmail: string().email('Host email must be a valid email')
          .notOneOf(['feeds@spreaker.com'], 'Email cannot be spreaker default "feeds@spreaker.com". Please login to your spreaker account, update your host email and try again.')
          .when([], {
            is: () => this.stepOneForm.hostingPlatform === HOSTING_PLATFORM_SPREAKER,
            then: (schema) => schema.required('Host email is required'),
            otherwise: (schema) => schema,
          }),
        tritonHostEmail: string().email('Host email must be a valid email')
          .when([], {
            is: () => this.stepOneForm.hostingPlatform === HOSTING_PLATFORM_TRITON,
            then: (schema) => schema.required('Host email is required'),
            otherwise: (schema) => schema,
          }),
        showDescription: string().required('Show description is required'),
        // eslint-disable-next-line no-template-curly-in-string
        averageMonthlyDownloads: number().required('Average monthly downloads is required').min(2500, 'Average monthly downloads must be greater than or equal to ${min}.'),
      }),
      stepOneForm: generateEmptyStepOneForm(),
      stepTwoSchema: object({
        categories: array().min(1, 'Must choose at least 1 category').required(),
      }),
      stepTwoForm: generateEmptyStepTwoForm(),
    };
  },
  mounted() {
    this.activeStep = 1;

    this.stepOneForm = generateEmptyStepOneForm();
    this.stepTwoForm = generateEmptyStepTwoForm();

    // set up form changes watchers
    this.formHasChanges = false;

    if (this.stepOneUnwatch) this.stepOneUnwatch();
    this.stepOneUnwatch = this.$watch('stepOneForm', function stepOneWatch() {
      this.formHasChanges = true;
    }, {
      deep: true,
    });

    if (this.stepTwoUnwatch) this.stepTwoUnwatch();
    this.stepTwoUnwatch = this.$watch('stepTwoForm', function stepTwoWatch() {
      this.formHasChanges = true;
    }, {
      deep: true,
    });

    window.addEventListener('beforeunload', this.beforeUnload);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  methods: {
    beforeUnload(event) {
      if (this.formHasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-alert
        alert('There are unsaved changes to your podcast. Are you sure you want to leave?');
      }
    },
    onCancel() {
      if (this.formHasChanges) {
        this.unsavedChangesDialogIsVisible = true;
        return;
      }

      this.$emit('cancel', true);
    },
    async importRss(rssUrl, platformOption) {
      const res = await api.scrapeProgramMetadata({
        programUrl: rssUrl,
      });

      this.stepOneForm.name = getSanitizedValue(res, 'metadata.channel.title', '', 'string');
      this.stepOneForm.hostName = getSanitizedValue(res, 'metadata.channel.itunesowner.itunesname', '', 'string');
      this.stepOneForm.spreakerHostEmail = platformOption === HOSTING_PLATFORM_SPREAKER
        ? getSanitizedValue(res, 'metadata.channel.itunesowner.itunesemail', '', 'string')
        : '';
      this.stepOneForm.tritonHostEmail = platformOption === HOSTING_PLATFORM_TRITON
        ? getSanitizedValue(res, 'metadata.channel.itunesowner.itunesemail', '', 'string')
        : '';

      // show description
      if (getSanitizedValue(res, 'metadata.channel.description', '', 'string')) {
        this.stepOneForm.showDescription = getSanitizedValue(res, 'metadata.channel.description', '', 'string');
      } else if (getSanitizedValue(res, 'metadata.channel.itunessummary', '', 'string')) {
        this.stepOneForm.showDescription = getSanitizedValue(res, 'metadata.channel.itunessummary', '', 'string');
      } else if (getSanitizedValue(res, 'metadata.channel.itunessubtitle', '', 'string')) {
        this.stepOneForm.showDescription = getSanitizedValue(res, 'metadata.channel.itunessubtitle', '', 'string');
      } else {
        this.stepOneForm.showDescription = '';
      }

      // categories

      // preselect categories for pilot
      const preselectedCategoryOptions = typeof process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES === 'string'
      && process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES !== ''
        ? process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES.split(',').map((id) => Number(id))
        : [];

      if (
        res.metadata
        && res.metadata.channel
        && res.metadata.channel.itunescategory
        && res.metadata.channel.itunescategory.forEach
      ) {
        const getCategoryFromObject = (item) => (item['@attributes'] && item['@attributes'].text
          ? item['@attributes'].text
          : null
        );

        const itunesCategories = res.metadata.channel.itunescategory.reduce((acc, item) => {
          const category = getCategoryFromObject(item);

          if (category) {
            acc.push(category);
          }

          if (item.itunescategory && item.itunescategory.forEach) {
            item.itunescategory.forEach((subItem) => {
              const subCategory = getCategoryFromObject(subItem);

              if (subCategory) {
                acc.push(subCategory);
              }
            });
          } else if (item.itunescategory && typeof item.itunescategory === 'object') {
            const subCategory = getCategoryFromObject(item.itunescategory);

            if (subCategory) {
              acc.push(subCategory);
            }
          }

          return acc;
        }, []);

        const matchingCategories = this.categoryOptions
          .filter((item) => itunesCategories.includes(item.label))
          .map((item) => item.value);

        this.stepTwoForm.categories = _uniq([...matchingCategories, ...preselectedCategoryOptions]);
      } else if (_get(res, 'metadata.channel.itunescategory.@attributes.text', '')) {
        // match single category
        const category = _get(res, 'metadata.channel.itunescategory.@attributes.text', '');

        const matchingCategories = this.categoryOptions
          .filter((item) => item.label === category)
          .map((item) => item.value);

        this.stepTwoForm.categories = _uniq([...matchingCategories, ...preselectedCategoryOptions]);
      }

      // spreaker show id
      const spreakerShowId = getSanitizedValue(res, 'metadata.channel.spreakerid', '', 'string');
      this.stepOneForm.spreakerShowId = spreakerShowId === '' || Number.isNaN(Number(spreakerShowId))
        ? null
        : Number(spreakerShowId);
    },
    onSubmitStepOne() {
      this.activeStep = 2;
    },
    onSubmitStepTwo() {
      // sanitize data
      let hostEmail = '';
      let rssUrl = '';
      switch (this.stepOneForm.hostingPlatform) {
        case HOSTING_PLATFORM_SPREAKER:
          hostEmail = this.stepOneForm.spreakerHostEmail;
          rssUrl = this.stepOneForm.spreakerRssUrl;
          break;
        case HOSTING_PLATFORM_TRITON:
          hostEmail = this.stepOneForm.tritonHostEmail;
          rssUrl = this.stepOneForm.tritonRssUrl;
          break;
        default:
          // do nothing
      }

      this.$emit('submit', {
        programForm: {
          ...this.stepOneForm,
          spreakerHostEmail: undefined,
          spreakerRssUrl: undefined,
          tritonHostEmail: undefined,
          tritonRssUrl: undefined,
          hostEmail,
          rssUrl,
          ...this.stepTwoForm,
        },
      });
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onClickPrevious() {
      switch (this.activeStep) {
        case 1:
          this.onCancel();
          break;
        case 2:
          this.activeStep = 1;
          break;
        default:
          // do nothing
      }
    },
    async onBlurSpreakerRss(url) {
      try {
        this.stepOneSchema.validateSyncAt('spreakerRssUrl', {
          spreakerRssUrl: url,
        });
      } catch {
        // rss validation did not pass. ignore error
        return;
      }

      try {
        await this.importRss(url, HOSTING_PLATFORM_SPREAKER);

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully imported Spreaker data',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error importing Spreaker data');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onBlurTritonRss(url) {
      try {
        this.stepOneSchema.validateSyncAt('tritonRssUrl', {
          tritonRssUrl: url,
        });
      } catch {
        // rss validation did not pass. ignore error
        return;
      }

      try {
        await this.importRss(url, HOSTING_PLATFORM_TRITON);

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully imported RSS data',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error importing RSS data');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  max-width: 800px;
  width: 100%;
}

.program-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
