export const generateUserSetupKey = (userId) => `has_seen_user_setup_${userId}`;

export const generateOrganizationSkipKey = (organizationId) => `skip_organization_rename_org_${organizationId}`;

export const generateProgramSkipKey = (organizationId) => `skip_program_create_org_${organizationId}`;

export const generateVoiceprintSkipKey = (userId) => `skip_voiceprint_create_user_${userId}`;

export const generateCampaignSkipKey = (organizationId) => `skip_campaign_create_org_${organizationId}`;

export const generateAdRegenerateAttemptCountKey = (adId) => `ad_regenerate_attempt_count_${adId}`;
