<template>
  <div class="setup-container py-4 px-3">
    <ScrollContainer>
      <CreateOrganizationForm
        v-if="isCreateOrganizationFormVisible"
        :isSubmitting="isCreateOrganizationSubmitting"
        avatarIcon="pi pi-microphone"
        :allowSkip="false"
        :collectCategories="true"
        :categoryOptions="IAB_CATEGORY_OPTIONS"
        @submit="onSubmitOrganization"
        @cancel="onCancelOrganization"
      />

      <div
        v-else-if="isPaymentFormVisible"
        :style="{
          width: '800px',
          margin: '0 auto',
        }"
      >
        <Payment
          :redirectUrl="paymentRedirectUrl"
        />
      </div>

      <CampaignForm
        v-else-if="isCreateCampaignFormVisible"
        :isSubmitting="isCreateCampaignSubmitting"
        :categoryOptions="categoriesStore.categoryOptions"
        :sensitiveCategoryOptions="categoriesStore.sensitiveCategoryOptions"
        cancelButtonLabel="Skip for now"
        @submit="onSubmitCampaign"
        @cancel="onCancelCampaign"
      />
    </ScrollContainer>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapStores } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from '@stripe/stripe-js';
import {
  useMyUserStore,
  useCategoriesStore,
  useCampaignsStore,
  useSettingsStore,
} from '@/stores';
import CreateOrganizationForm from '@/components/createOrganizationForm';
import CampaignForm from '@/components/campaignForm';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { generateOrganizationSkipKey } from '@/utils/localStorageKeys';
import { ROUTE_ADVERTISER_DASHBOARD } from '@/router/routes';
import Payment from '@/components/stripe/payment';
import ScrollContainer from '@/components/scrollContainer';
import { IAB_CATEGORY_OPTIONS } from '@/constants';

const PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

const generateCampaignSkipKey = (organizationId) => `skip_campaign_create_org_${organizationId}`;

export default {
  components: {
    CreateOrganizationForm,
    CampaignForm,
    Payment,
    ScrollContainer,
  },
  computed: {
    ...mapStores(useMyUserStore, useCategoriesStore, useCampaignsStore, useSettingsStore),
  },
  data() {
    return {
      isCreateOrganizationFormVisible: false,
      isCreateOrganizationSubmitting: false,

      isPaymentFormVisible: false,
      wasStripeSetupSuccessful: false,
      setupIntentClientSecret: null,

      isCreateCampaignFormVisible: false,
      isCreateCampaignSubmitting: false,

      paymentRedirectUrl: window.location.href,
      IAB_CATEGORY_OPTIONS,

      preloadedCategories: [],
    };
  },
  async mounted() {
    if (this.$route.query.setup_intent_client_secret) {
      this.setupIntentClientSecret = this.$route.query.setup_intent_client_secret;
      const stripe = await loadStripe(PUBLISHABLE_KEY);

      // check if query param exists to determine if stripe payment setup has succeeded
      const stripeRes = await stripe.retrieveSetupIntent(this.setupIntentClientSecret);

      if (stripeRes.setupIntent && stripeRes.setupIntent.status === 'succeeded') {
        this.wasStripeSetupSuccessful = true;
      }
    }

    if (this.myUserStore.organizationHasTapAdvertiser) {
      await this.getOrganizationCategories();
    }

    await this.auditAccountSetup();
  },
  methods: {
    async getOrganizationCategories() {
      try {
        await this.myUserStore.getOrganizationTapCategories();

        this.preloadedCategories = [...this.myUserStore.organizationTapCategories];
      } catch (error) {
        const message = parseMessageFromError(error, 'Error reading organization categories.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async auditAccountSetup() {
      try {
        // organization workflow
        const organizationId = this.myUserStore.myOrganization.id;
        const organizationHasName = !!this.myUserStore.myOrganization
          && !!this.myUserStore.myOrganization.name;

        const orgnizationSkipKey = generateOrganizationSkipKey(organizationId);
        const skipOrganizationRename = !!localStorage.getItem(orgnizationSkipKey);

        if (skipOrganizationRename || organizationHasName) {
          this.isCreateOrganizationFormVisible = false;
        } else {
          this.isCreateOrganizationFormVisible = true;
          return;
        }

        this.isCreateOrganizationDialogVisible = false;

        // payment workflow
        if (
          this.settingsStore.paymentSystemEnabled
          && (
            !this.myUserStore.organizationHasPaymentMethod
            || (!!this.setupIntentClientSecret && !this.wasStripeSetupSuccessful)
          )
        ) {
          this.isPaymentFormVisible = true;
          return;
        }

        // campaign workflow

        const campaignSkipKey = generateCampaignSkipKey(organizationId);
        const skipCampaignCreate = !!localStorage.getItem(campaignSkipKey);
        if (skipCampaignCreate) {
          this.isCreateCampaignFormVisible = false;

          this.$router.push({
            name: ROUTE_ADVERTISER_DASHBOARD,
          });
          return;
        }

        // check if user org has atleast 1 campaign
        await this.campaignsStore.getCampaigns({
          organizationId,
        });
        const campaigns = this.campaignsStore
          .getCampaignsByOrganizationId(organizationId);

        const organizationHasCampaigns = campaigns.length > 0;
        // if not then show create campaign dialog
        if (!organizationHasCampaigns) {
          this.isCreateCampaignFormVisible = true;
          return;
        }

        this.isCreateCampaignFormVisible = false;

        this.$router.push({
          name: ROUTE_ADVERTISER_DASHBOARD,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error setting up account. Please refresh the page and try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitOrganization({
      organizationForm,
      invitedUsers,
    }) {
      try {
        this.isCreateOrganizationSubmitting = true;
        const organizationId = this.myUserStore.myOrganization.id;
        const { organizationHasTapAdvertiser } = this.myUserStore;

        await api.updateOrganization({
          organizationId,
          name: organizationForm.name,
        });

        if (organizationHasTapAdvertiser) {
          await api.updateAdvertiserCategories({
            organizationId,
            iabCodes: organizationForm.iabCodes,
          });
        } else {
          await api.createAdvertiser({
            organizationId,
            name: `${organizationForm.name} ${uuidv4()}`,
            iabCodes: organizationForm.iabCodes,
          });
        }

        if (invitedUsers.length > 0) {
          try {
            await Promise.all(invitedUsers.map((user) => api.inviteUser({
              email: user.email,
              organizationId,
            })));
          } catch (error) {
            const message = parseMessageFromError(error, 'Organization created, but failed to invite users. Please visit the organization page and re-invite users.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created organization',
        });

        await this.myUserStore.getMyUser();
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateOrganizationSubmitting = false;
      }
    },
    async onCancelOrganization() {
      try {
        localStorage.setItem(generateOrganizationSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitCampaign({ campaignForm }) {
      try {
        this.isCreateCampaignSubmitting = true;

        const targetAges = campaignForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const adScript = campaignForm.campaignScript ? campaignForm.campaignScript : null;
        const adDocument = campaignForm.adDocument instanceof File
          ? campaignForm.adDocument
          : null;

        await api.createCampaign({
          campaignName: campaignForm.name,
          organizationId: this.myUserStore.myOrganization.id,
          startDate: DateTime.fromJSDate(campaignForm.dates[0]).toISODate(),
          endDate: DateTime.fromJSDate(campaignForm.dates[1]).toISODate(),
          maxBudget: campaignForm.budget,
          budgetAllocation: campaignForm.budgetAllocation,
          targetGender: campaignForm.targetGender,
          targetAges,
          allowExplicit: campaignForm.allowExplicit,
          targetCategories: campaignForm.categories,
          adRunSlot: campaignForm.adRunSlot,
          adTitle: campaignForm.adTitle,
          adLength: campaignForm.adLength,
          bidCap: campaignForm.bidCap,
          frequencyCap: campaignForm.frequencyCap,
          adScript,
          adDocument,
          extTrackPixel: campaignForm.trackingPixel,
          sensitiveCategories: campaignForm.sensitiveCategories,
          exampleGeneratedAdId: campaignForm.exampleGeneratedAdId,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created campaign',
        });

        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding campaign.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateCampaignSubmitting = false;
      }
    },
    async onCancelCampaign() {
      try {
        localStorage.setItem(generateCampaignSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-container {
  height: calc(100vh - 67px);
  overflow-y: auto;
}
</style>
